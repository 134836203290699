import CrudService from "../../services/CrudService";
import WissellijstService from "../../services/WissellijstService";

const namespaced = true;

const state = {
  wissellijstenRegelsHistoryMetadata: [],
  wissellijstenRegelsHistoryData: {},
  machineNaam: null,
};

const mutations = {
  SET_WISSELLIJSTEN_REGELS_METADATA(state, payload) {
    payload.properties.push({
      variableName: "printLabel",
      displayName: "Print",
      pinned: true,
      displayType: 10,
      disabledConditions: [
        {
          propertyName: "werkelijkeToolOut",
          notEmpty: false,
        },
      ],
    });
    state.wissellijstenRegelsHistoryMetadata = payload;
  },

  SET_WISSELLIJSTEN_REGELS_DATA(state, payload) {
    state.wissellijstenRegelsHistoryData = payload;
  },

  SET_MACHINE_NAAM(state, payload) {
    state.machineNaam = payload;
  },
};

const actions = {
  getWissellijstenRegelsHistoryMetadata({ commit }) {
    CrudService.getMetadataByName("wissellijstregels", "grid")
      .then((response) => {
        commit("SET_WISSELLIJSTEN_REGELS_METADATA", response.data);
      })
      .catch((error) => {
        commit("SET_ERROR", error, { root: true });
        throw error;
      });
  },

  getWissellijstenRegelsHistoryData({ commit }, wissellijstId) {
    WissellijstService.get(wissellijstId)
      .then((response) => {
        commit(
          "SET_WISSELLIJSTEN_REGELS_DATA",
          response.data.value.wissellijst.regels
        );
      })
      .catch((error) => {
        commit("SET_ERROR", error, { root: true });
        throw error;
      });
  },

  printLabels({ commit }, regels) {
    CrudService.postData(
      "werkelijketools/printLabels/vervangingen",
      regels.map((x) => x.werkelijkeToolOutId)
    )
      .then((response) => {
        commit(
          "HANDLE_RESPONSE",
          {
            response: response.data,
            successMessage: "De labels worden geprint.",
          },
          { root: true }
        );
      })
      .catch((error) => {
        commit("SET_ERROR", error, { root: true });
        throw error;
      });
  },

  getMachine({ commit }, machineId) {
    CrudService.getDataById("machines", machineId)
      .then((response) => {
        if (response.data.success) {
          commit("SET_MACHINE_NAAM", response.data.value.naam);
        }
      })
      .catch((error) => {
        commit("SET_ERROR", error, { root: true });
        throw error;
      });
  },
};

const tabletwissellijstenhistoryregels = {
  state,
  mutations,
  actions,
  namespaced,
};

export default tabletwissellijstenhistoryregels;
